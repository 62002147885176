import tailwindStylesheetUrl from "@/app/app.css?url";
import { Footer } from "@/app/components/footer";
import { GeneralErrorBoundary } from "@/app/components/general-error-boundary.tsx";
import { Header } from "@/app/components/header";
import { getUserId, signout } from "@/app/lib/auth.server";
import { getEnv } from "@/app/lib/env.server.ts";
import * as gtag from "@/app/lib/gtag.client";
import { combineHeaders, getDomainUrl } from "@/app/lib/misc.tsx";
import { useNonce } from "@/app/lib/nonce-provider.ts";
import { makeTimings, time } from "@/app/lib/timing.server";
import {
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { db } from "db/index";
import { eq } from "drizzle-orm";
import { type ReactNode, useEffect } from "react";

export const links: LinksFunction = () => [
  {
    rel: "alternate icon",
    type: "image/png",
    href: "/favicons/favicon-32x32.png",
  },
  { rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
  {
    rel: "manifest",
    href: "/site.webmanifest",
    crossOrigin: "use-credentials",
  } as const,
  // { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
  { rel: "stylesheet", href: tailwindStylesheetUrl },
];

// export const meta: MetaFunction<typeof loader> = ({ data }) => {
//   return [
//     { title: data ? "HelpSaúde" : "Erro | HelpSaúde" },
//     { name: "description", content: `Your own captain's log` },
//   ];
// };

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings("root loader");
  // const userId = await time(() => getUserId(), {
  //   timings,
  //   type: "getUserId",
  //   desc: "getUserId in root",
  // });

  // const user = userId
  //   ? await time(
  //       () =>
  //         db
  //           .select({
  //             id: user.id,
  //             name: user.name,
  //             isLockedOut: user.isLockedOut,
  //             hasValidEmail: user.hasValidEmail,
  //             wantsNewsletter: user.wantsNewsletter,
  //             isTest: user.isTest,
  //             createdAt: user.createdAt,
  //             updatedAt: user.updatedAt,
  //           })
  //           .from(user)
  //           .where(eq(user.id, userId))
  //           .limit(1)
  //           .then((results) => results[0]),
  //       { timings, type: "find user", desc: "find user in root" },
  //     )
  //   : null;
  // if (userId && !user) {
  //   console.info("something weird happened");
  //   // something weird happened... The user is authenticated but we can't find
  //   // them in the database. Maybe they were deleted? Let's log them out.
  //   await signout();
  // }
  // const { toast, headers: toastHeaders } = await getToast(request);
  // const honeyProps = honeypot.getInputProps();

  return json(
    {
      // user,
      requestInfo: {
        // hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        // userPrefs: {
        //   theme: getTheme(request),
        // },
      },
      ENV: getEnv(),
      // toast,
      // honeyProps,
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        // toastHeaders,
      ),
    },
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
  };
  return headers;
};

export function Layout({ children }: { children: ReactNode }): JSX.Element {
  const data = useRouteLoaderData("root");
  const location = useLocation();
  const nonce = useNonce();
  const gaTrackingId = "GTM-TNB8CLWV";

  useEffect(() => {
    if (gaTrackingId.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location]);

  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="HelpSaúde" name="author" />
        <meta content="HelpSaúde (2024)" name="copyright" />
        <meta content="#274fad" name="theme-color" />
        <meta content="light" name="color-scheme" />
        <meta content="index, follow" name="robots" />
        <meta content="Helpsaúde" name="application-name" />
        <Links />
      </head>
      <body>
        {!gaTrackingId ? null : (
          <>
            {process.env.NODE_ENV !== "development" && (
              <>
                <script
                  // nonce={nonce}
                  async
                  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9847031185780341"
                  crossOrigin="anonymous"
                />
                <script
                  // nonce={nonce}
                  async
                  src={`https://metricas.helpsaude.com/gtm.js?id=${gaTrackingId}`}
                />
                <script
                  // nonce={nonce}
                  async
                  id="gtag-init"
                  // biome-ignore lint/security/noDangerouslySetInnerHtml: <no other way to do this...>
                  dangerouslySetInnerHTML={{
                    __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
                  }}
                />
              </>
            )}
          </>
        )}
        <Header />
        {children}
        <Footer />
        <script
          // nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App(): JSX.Element {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary(): JSX.Element {
  const nonce = useNonce();
  return <GeneralErrorBoundary />;
}
